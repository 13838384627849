import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ptBR } from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import ColorModeContext from "./layout/themeContext";
import api from "../src/services/api";
import Routes from "./routes";
import { SocketContext, socketManager } from './context/Socket/SocketContext';
const queryClient = new QueryClient();


const App = () => {
  const [locale, setLocale] = useState();
  const [mainColor, setMainColor] = useState("#000000");
  const [scrollbarColor, setScrollbarColor] = useState("#000000");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const preferredTheme = window.localStorage.getItem("preferredTheme");
  const [mode, setMode] = useState(
    preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light"
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("preferredTheme", mode);
  }, [mode]);

  useEffect(() => {
    const fetchMainColor = async () => {
      try {
        const response = await api.get("/settings/mainColor");
        const fetchedColor = response.data?.value;
        setMainColor(fetchedColor || "#000000");
      } catch (error) {
        console.error("Error retrieving main color", error);
      }
    };

    const fetchScrollbarColor = async () => {
      try {
        const response = await api.get("/settings/scrollbarColor");
        const fetchedColor = response.data?.value;
        setScrollbarColor(fetchedColor || "#000000");
      } catch (error) {
        console.error("Error retrieving scrollbar color", error);
      }
    };

    fetchMainColor();
    fetchScrollbarColor();
  }, []);

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: '8px',
          height: '8px',
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          backgroundColor: scrollbarColor,
        },
      },
      scrollbarStylesSoft: {
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: scrollbarColor,
        },
      },
      palette: {
        type: mode,
        primary: { main: mode === "light" ? mainColor : "#000000 !important" },
        textPrimary: mode === "light" ? "#000" : "#000000",
        borderPrimary: mode === "light" ? "#000" : "#000000",
        dark: { main: mode === "light" ? "#333333" : "#F3F3F3" },
        light: { main: mode === "light" ? "#F3F3F3" : "#333333" },
        tabHeaderBackground: mode === "light" ? "#fff" : "#333",
        optionsBackground: mode === "light" ? "#fafafa" : "#333",
        options: mode === "light" ? "#fafafa" : "#333",
        fontecor: mode === "light" ? "#000" : "#000000",
        fancyBackground: mode === "light" ? "#fafafa" : "#333",
        bordabox: mode === "light" ? "#eee" : "#333",
        newmessagebox: mode === "light" ? "#eee" : "#333",
        inputdigita: mode === "light" ? "#fff" : "#333",
        contactdrawer: mode === "light" ? "#fff" : "#333",
        announcements: mode === "light" ? "#fff" : "#000000",
        login: mode === "light" ? "#fff" : "#333",
        announcementspopover: mode === "light" ? "#fff" : "#333",
        chatlist: mode === "light" ? "#eee" : "#333",
        boxlist: mode === "light" ? "#ededed" : "#333",
        boxchatlist: mode === "light" ? "#ededed" : "#333",
        total: mode === "light" ? "#fff" : "#222",
        messageIcons: mode === "light" ? "#ffffff" : "#000000",
        inputBackground: mode === "light" ? "#FFFFFF" : "#333",
        barraSuperior: mode === "light" ? { main: mainColor } : "#000000 !important",
        boxticket: mode === "light" ? "#EEE" : "#333",
        campaigntab: mode === "light" ? "#ededed" : "#333",
      },
    },
    locale
  );



  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("preferredTheme", mode);
  }, [mode]);

  useEffect(() => {
    const fetchMainColor = async () => {
      try {
        const response = await api.get('/settings/mainColor');
        const fetchedColor = response.data?.value;
        setMainColor(fetchedColor || '#000000');
      } catch (error) {
        console.error('Error retrieving main color', error);
      }
    };

    const fetchScrollbarColor = async () => {
      try {
        const response = await api.get('/settings/scrollbarColor');
        const fetchedColor = response.data?.value;
        setScrollbarColor(fetchedColor || '#000000');
      } catch (error) {
        console.error('Error retrieving scrollbar color', error);
      }
    };

    fetchMainColor();
    fetchScrollbarColor();
  }, []);

  return (
    <ColorModeContext.Provider value={{ colorMode }}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SocketContext.Provider value={socketManager}>
            <Routes />
          </SocketContext.Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
