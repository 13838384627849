import React, { useState } from "react";
import { Box, Typography, MenuItem, Select, FormControl, InputLabel, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { toast } from "react-toastify"; // Biblioteca para feedback de cópia
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'; // Importa o SyntaxHighlighter
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Usando apenas o tema escuro

const useStyles = makeStyles((theme) => ({
    codeBox: {
      backgroundColor: "#424242", // Sempre mantém o fundo escuro
      padding: theme.spacing(2),
      borderRadius: "8px",
      marginTop: theme.spacing(2),
      fontSize: '1rem', // Aumenta o tamanho da fonte
      lineHeight: '1.5', // Aumenta o espaçamento entre as linhas
      maxHeight: '400px', // Define uma altura máxima para o box de código
      overflowY: 'auto', // Permite a rolagem quando necessário
      position: 'relative',
    },
    selectBox: {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    copyIcon: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo semitransparente para melhorar contraste
      color: "#ffffff",
      padding: theme.spacing(0.5), // Pequeno padding para facilitar o clique
      borderRadius: "4px", // Arredondando os cantos
      zIndex: 10, // Garante que o botão fique acima do código
      transition: "background-color 0.3s", // Adiciona uma transição suave
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.2)", // Cor de fundo ao passar o mouse
      },
    },
  }));  

const examples = {
    javascript: `const sendMessage = async () => {
    const token = "seu_token_aqui";
    const url = "seuback/api/messages/send"; // URL estática
    const data = {
      number: "558599999999",
      body: "Olá, tudo bem?",
      userId: "1",
      queueId: "1"
    };
    try {
      const response = await axios.post(url, data, {
        headers: {
          'Authorization': \`Bearer \${token}\`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Resposta:', response.data);
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    }
  };`,
  
    php: `<?php
  $token = "seu_token_aqui";
  $url = "seuback/api/messages/send"; // URL estática
  $data = [
    'number' => '558599999999',
    'body' => 'Olá, tudo bem?',
    'userId' => '1',
    'queueId' => '1'
  ];
  
  $options = [
    'http' => [
      'header'  => "Authorization: Bearer " . $token . "\\r\\n" .
                   "Content-type: application/json\\r\\n",
      'method'  => 'POST',
      'content' => json_encode($data),
    ],
  ];
  
  $context  = stream_context_create($options);
  $result = file_get_contents($url, false, $context);
  if ($result === FALSE) {
    /* Handle error */
  }
  
  echo $result;
  ?>`,
  
    python: `import requests
  
  token = "seu_token_aqui"
  url = "seuback/api/messages/send" # URL estática
  
  data = {
    "number": "558599999999",
    "body": "Olá, tudo bem?",
    "userId": "1",
    "queueId": "1"
  }
  
  headers = {
      "Authorization": f"Bearer {token}",
      "Content-Type": "application/json"
  }
  
  response = requests.post(url, json=data, headers=headers)
  print(response.json())`,
  
    java: `import java.net.HttpURLConnection;
  import java.net.URL;
  import java.io.OutputStream;
  
  public class SendMessage {
      public static void main(String[] args) throws Exception {
          String token = "seu_token_aqui";
          String url = "seuback/api/messages/send"; // URL estática
  
          String jsonInputString = "{" +
                  "\\"number\\": \\"558599999999\\"," +
                  "\\"body\\": \\"Olá, tudo bem?\\"," +
                  "\\"userId\\": \\"1\\"," +
                  "\\"queueId\\": \\"1\\"}";
  
          URL obj = new URL(url);
          HttpURLConnection con = (HttpURLConnection) obj.openConnection();
          con.setRequestMethod("POST");
          con.setRequestProperty("Authorization", "Bearer " + token);
          con.setRequestProperty("Content-Type", "application/json");
          con.setDoOutput(true);
  
          try (OutputStream os = con.getOutputStream()) {
              byte[] input = jsonInputString.getBytes("utf-8");
              os.write(input, 0, input.length);
          }
  
          int responseCode = con.getResponseCode();
          System.out.println("POST Response Code :: " + responseCode);
      }
  };`,
  
    csharp: `using System;
  using System.Net.Http;
  using System.Text;
  using System.Threading.Tasks;
  
  public class SendMessage {
      private static readonly HttpClient client = new HttpClient();
      public static async Task Main(string[] args) {
          var token = "seu_token_aqui";
          var url = "seuback/api/messages/send"; // URL estática
  
          var json = "{" +
                     "\\"number\\": \\"558599999999\\"," +
                     "\\"body\\": \\"Olá, tudo bem?\\"," +
                     "\\"userId\\": \\"1\\"," +
                     "\\"queueId\\": \\"1\\"}";
  
          var content = new StringContent(json, Encoding.UTF8, "application/json");
          client.DefaultRequestHeaders.Add("Authorization", "Bearer " + token);
          var response = await client.PostAsync(url, content);
  
          Console.WriteLine("Response: " + await response.Content.ReadAsStringAsync());
      }
  };`,
  
    cpp: `#include <iostream>
  #include <string>
  #include <curl/curl.h>
  
  int main() {
      std::string token = "seu_token_aqui";
      std::string url = "seuback/api/messages/send"; // URL estática
      std::string data = "{\\"number\\":\\"558599999999\\",\\"body\\":\\"Olá, tudo bem?\\",\\"userId\\":\\"1\\",\\"queueId\\":\\"1\\"}";
  
      CURL* curl = curl_easy_init();
      if (curl) {
          struct curl_slist* headers = NULL;
          headers = curl_slist_append(headers, ("Authorization: Bearer " + token).c_str());
          headers = curl_slist_append(headers, "Content-Type: application/json");
  
          curl_easy_setopt(curl, CURLOPT_URL, url.c_str());
          curl_easy_setopt(curl, CURLOPT_POSTFIELDS, data.c_str());
          curl_easy_setopt(curl, CURLOPT_HTTPHEADER, headers);
  
          CURLcode res = curl_easy_perform(curl);
          if (res != CURLE_OK) {
              std::cerr << "Request failed: " << curl_easy_strerror(res) << std::endl;
          }
          curl_easy_cleanup(curl);
      }
  };`,
  
    go: `package main
  
  import (
    "bytes"
    "encoding/json"
    "fmt"
    "net/http"
  )
  
  func main() {
    token := "seu_token_aqui"
    url := "seuback/api/messages/send" // URL estática
  
    data := map[string]string{
      "number":  "558599999999",
      "body":    "Olá, tudo bem?",
      "userId":  "1",
      "queueId": "1",
    }
    jsonData, _ := json.Marshal(data)
  
    req, _ := http.NewRequest("POST", url, bytes.NewBuffer(jsonData))
    req.Header.Set("Authorization", "Bearer "+token)
    req.Header.Set("Content-Type", "application/json")
  
    client := &http.Client{}
    resp, err := client.Do(req)
    if err != nil {
      fmt.Println("Erro:", err)
      return
    }
    defer resp.Body.Close()
    fmt.Println("Response Status:", resp.Status)
  }`
  };  

const IntegrationExamples = ({ getEndpoint }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedLanguage, setSelectedLanguage] = useState("javascript");

  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(examples[selectedLanguage]);
    toast.success("Exemplo copiado para a área de transferência!");
  };

  const languageMapping = {
    javascript: "javascript",
    php: "php",
    python: "python",
    java: "java",
    csharp: "csharp",
    cpp: "cpp",
    go: "go",
  };

  return (
    <Box>
      <FormControl className={classes.selectBox}>
        <InputLabel>Linguagem de Programação</InputLabel>
        <Select value={selectedLanguage} onChange={handleChange}>
          <MenuItem value="javascript">JavaScript</MenuItem>
          <MenuItem value="php">PHP</MenuItem>
          <MenuItem value="python">Python</MenuItem>
          <MenuItem value="java">Java</MenuItem>
          <MenuItem value="csharp">C#</MenuItem>
          <MenuItem value="cpp">C++</MenuItem>
          <MenuItem value="go">Go</MenuItem>
        </Select>
      </FormControl>

      <Box className={classes.codeBox}>
        <Tooltip title="Copiar código">
          <IconButton className={classes.copyIcon} onClick={copyToClipboard}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        <SyntaxHighlighter
          language={languageMapping[selectedLanguage]}
          style={materialDark} // Define o fundo escuro, mesmo no modo claro
        >
          {examples[selectedLanguage]}
        </SyntaxHighlighter>
      </Box>
    </Box>
  );
};

export default IntegrationExamples;
