/* eslint-disable no-console */
import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { StyledMainHeader } from '../GoogleCalendarComponent/styles';
import { Container } from './styles';

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    width: '100%',
    height: '220px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  listPaper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(1),
    ...theme.scrollbarStyles,
    width: '100%',
    marginBottom: 'auto',
    minHeight: '200px',
  },
}));

const IntegrationLinkBox = ({
  title,
  description,
  icon,
  link,
  customStyle = {},
  img,
  onClick,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      window.location.href = link;
    }
  };

  return (
    <Paper className={classes.mainPaper} onClick={handleClick}>
      <StyledMainHeader style={{ backgroundColor: '#008080' }}>
        <span>{title}</span>
      </StyledMainHeader>
      <Container style={{ ...customStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {img && <img src={img} alt={title} style={{ maxWidth: '100%', maxHeight: '100%' }} />} {/* Ajuste de estilo para imagem */}
        {icon && <div>{icon}</div>}
        {description && <p>{description}</p>}
      </Container>
    </Paper>
  );
};

export default IntegrationLinkBox;
